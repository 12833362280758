﻿import { h, createApp } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

/**
 * element+
 */
import 'element-plus/dist/index.css';
import 'element-plus/theme-chalk/dark/css-vars.css'
import "./app.scss";
import { InertiaProgress } from '@inertiajs/progress';

/**
 * emitter
 */
import mitt from 'mitt';

const emitter = mitt();

/**
 * axios
 */
import axios from 'axios';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * font awesome
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';

library.add(fas);
library.add(far);

/**
 * moment
 */
import moment from 'moment';
import 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
momentDurationFormatSetup(moment);
/**
 * mask
 */
import { vMaska } from "maska";

/**
 * google maps
 */
import VueGoogleMaps from 'vue-google-maps-community-fork';
import { ElNotification } from 'element-plus';

createInertiaApp({
    resolve: name =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(VueGoogleMaps, {
                load: {
                    key: process.env.GOOGLE_MAP_API_KEY,
                    v: 'weekly',
                },
            })
            .component('font-awesome-icon', FontAwesomeIcon)
            .mixin({
                directives: {
                    maska: vMaska
                },

                data() {
                    return {
                        AERISWEATHER_ID: process.env.AERISWEATHER_ID,
                        AERISWEATHER_SECRET: process.env.AERISWEATHER_SECRET,
                        SITE_NAME: process.env.SITE_NAME,
                        GOOGLE_API_KEY: process.env.GOOGLE_MAP_API_KEY,
                        ENV: process.env.ENV,
                        CREATE_TRIP_PREFILL: process.env.CREATE_TRIP_PREFILL,

                        dateTimeFormat: 'YYYY-MM-DD HH:mm',

                        ElNotification,

                        moment,
                        svgProcessing: `
                            <path class="path" d="
                              M 30 15
                              L 28 17
                              M 25.61 25.61
                              A 15 15, 0, 0, 1, 15 30
                              A 15 15, 0, 1, 1, 27.99 7.5
                              L 15 15
                            " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
                        `,
                        emitter,
                        emailPattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        availableRoles: [
                            {
                                label: 'User',
                                value: 'User',
                            },
                            {
                                label: 'Support',
                                value: 'Support',
                            },
                            {
                                label: 'Owner',
                                value: 'Owner',
                            },
                            {
                                label: 'Admin',
                                value: 'Admin',
                            },
                        ],

                        cachedCoordsAddresses: {},
                    };
                },

                methods: {
                    setCachedCoordsAddress(lat, lng, address) {
                        this.cachedCoordsAddresses[[lat, lng].join('|')] = address;
                    },
                    checkCachedCoordsAddress(lat, lng, callback) {
                        if ([lat, lng].join('|') in this.cachedCoordsAddresses) {
                            callback(this.cachedCoordsAddresses[[lat, lng].join('|')]);
                            return;
                        }

                        this.geocodeByLatLng(
                            lat,
                            lng,
                            (data) => {
                                let address = data.results?.[1]?.formatted_address;
                                this.setCachedCoordsAddress(lat, lng, address);
                                callback(address);
                            },
                        );
                    },

                    geocodeByLatLng(lat, lng, callback) {
                        axios.get(`/Api/GoogleMap/GeocodingByLatLng_Get?latitude=${lat}&longitude=${lng}`)
                            .then(({ data }) => {
                                callback(data);
                            });
                    },

                    getMapOptions() {
                        let result = {
                            displayTraffic: this.getCookie('displayTraffic') === '1',
                            displayWeather: this.getCookie('displayWeather') === '1',
                            displayParkings: this.getCookie('displayParkings') === '1',
                            mapType: this.getCookie('mapType') ?? 'default',
                        };

                        return result;
                    },

                    setCookie(name, value, days) {
                        var expires = "";
                        if (days) {
                            var date = new Date();
                            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                            expires = "; expires=" + date.toUTCString();
                        }
                        document.cookie = name + "=" + (value || "") + expires + "; path=/";
                    },
                    getCookie(name) {
                        var nameEQ = name + "=";
                        var ca = document.cookie.split(';');
                        for (var i = 0; i < ca.length; i++) {
                            var c = ca[i];
                            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
                        }
                        return null;
                    },
                    eraseCookie(name) {
                        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    },

                    hasRole(user, role) {
                        return Array.isArray(user?.roles) && user.roles.map(r => r.trim()).includes(role);
                    },

                    goTo(link) {
                        this.$inertia.get(link);
                        event.preventDefault();
                    },

                    filtersEqual(from, to) {
                        let ignoreFields = ['total'];

                        for (let k in from) {
                            if (ignoreFields.includes(k)) {
                                continue;
                            }

                            if (from[k] != to[k]) {
                                return false;
                            }
                        }

                        return true;
                    },


                    replaceAll(string, from, to) {
                        for (let i = 0; i < from.length; i++) {
                            string = string.replaceAll(from[i], to[i]);
                        }

                        return string;
                    },
                },
            })
            .mount(el)
    },
});

InertiaProgress.init({ color: '#4B5563' });

window.onerror = (a, b, c, d, e) => {
    if (e && e.stack) axios.post(`/Api/Logs/Log`, { Level: 4, Message: e.stack });
    return true;
};